<template>
  <div class="content min_box" v-wechat-title="title">
    <nav-bar :title="title" />
    <div class="news_center">
      <div
        v-if="detail.industrial_detail"
        v-html="detail.industrial_detail.industrial_chain_description"
      ></div>
    </div>
    <div class="cneter">
      <!-- 推荐名企 -->
      <div v-if="detail.enters && detail.enters.length > 0">
        <div class="trailer_title">
          <span class="text">推荐名企</span>
        </div>
        <!-- 名企列表 -->
        <ul>
          <li @click="toMQ(item)" v-for="item in detail.enters" :key="item.id">
            <span>●</span>
            {{ item.enterprises_name }}
          </li>
        </ul>
      </div>
      <!-- 项目推荐 -->
      <div v-if="list.length > 0" style="margin-bottom: 30px">
        <div class="trailer_title">
          <span class="text">推荐项目</span>
        </div>
        <!-- 名企列表 -->
        <ul>
          <li @click="toproject(item)" v-for="(item,index) in list" :key="index">
            <span>●</span>
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/nav/nav";
import { industrial_detail, get_industry_list } from "@/api/http";
export default {
  name: "Vue",
  data() {
    return {
      type: 1,
      detail: {},
      url: [
        "gardenlist",
        "landlist",
        "workslist",
        "itemslist",
        "buildlist",
        "masslist",
        "more_enterprise",
        "more_business",
      ],
      list: [],
      title: "产业链详情",
    };
  },
  components: { navBar },
  created() {
    this.title = this.$route.params.titles + '详情'
    industrial_detail({
      region_code: this.$route.params.code,
      id: this.$route.params.id,
    }).then((res) => {
      this.detail = res.data;
      this.loadLists();
    });
  },
  methods: {
    toMQ(e) {
      this.$router.push({
        name: "FamousEnterprises",
        params: {
          code: this.$route.params.code,
          id: e.id,
        },
      });
    },
    toproject(e) {
      let index = e.cate;
      let arr = ['Park', 'Massif', 'Workshop', 'Project', 'Building', 'PopularEn', 'FamousEnterprises',
					'BusinessAssociation'
				]
      this.$router.push({
        name: arr[index - 1],
        params: {
          code: this.$route.params.code,
          id: e.id,
        },
      });
    },
    //列表
    loadLists() {
     let indus_id = this.detail.industrial_detail.id;
      var data = [];
      get_industry_list(
        {    
          indus_id: indus_id,
        },
      ).then((res) => {
        data = res.data.data;
        if(data.length == 0){
          return
        }
        this.list = data;

        // if (index == 1) {
        //   data = res.data.gardens.data;
        //   if (data.length > 0) {
        //     data.forEach((v, index) => {
        //       this.list.push({
        //         id: v.id,
        //         name: v.garden_name,
        //         Latitude: v.Latitude,
        //         Longitude: v.Longitude,
        //       });
        //     });
        //   }
        // } else if (index == 2) {
        //   data = res.data.land.data;
        //   if (data.length > 0) {
        //     data.forEach((v, index) => {
        //       this.list.push({
        //         id: v.id,
        //         name: v.land_name,
        //         Latitude: v.Latitude,
        //         Longitude: v.Longitude,
        //       });
        //     });
        //   }
        // } else if (index == 3) {
        //   data = res.data.workslist.data;
        //   if (data.length > 0) {
        //     data.forEach((v, index) => {
        //       this.list.push({
        //         id: v.id,
        //         name: v.works_name,
        //         Latitude: v.Latitude,
        //         Longitude: v.Longitude,
        //       });
        //     });
        //   }
        // } else if (index == 4) {
        //   data = res.data.itemslists.data;
        //   if (data.length > 0) {
        //     data.forEach((v, index) => {
        //       this.list.push({
        //         id: v.id,
        //         name: v.items_name,
        //         Latitude: v.Latitude,
        //         Longitude: v.Longitude,
        //       });
        //     });
        //   }
        // } else if (index == 5) {
        //   data = res.data.buildlists.data;
        //   if (data.length > 0) {
        //     data.forEach((v, index) => {
        //       this.list.push({
        //         id: v.id,
        //         name: v.build_name,
        //         Latitude: v.Latitude,
        //         Longitude: v.Longitude,
        //       });
        //     });
        //   }
        // } else if (index == 6) {
        //   data = res.data.masslists.data;
        //   if (data.length > 0) {
        //     data.forEach((v, index) => {
        //       this.list.push({
        //         id: v.id,
        //         name: v.mass_name,
        //         Latitude: v.Latitude,
        //         Longitude: v.Longitude,
        //       });
        //     });
        //   }
        // }
      });
    },
  },
};
</script>
<style lang='less' scoped>
.news_center {
  padding: 14px;
}
/* 头部标签 */
.trailer_title {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
}
.trailer_title .text {
  font-size: 1.2rem;
}
.trailer_title:before {
  content: "";
  border-radius: 20px;
  width: 10px;
  height: 30px;
  display: inline-block;
  background-color: #35a3bd;
  margin-right: 10px;
}
.cneter {
  padding: 0 10px;
}
ul li {
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
}
</style>